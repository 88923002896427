.slick-slider {
  .slick-list {
    width: 95%;
    margin: auto;
  }
  .slick-arrow.slick-prev {
    left: -5px;
    z-index: 5;
  }

  .slick-arrow.slick-prev::before {
    color: black;
  }

  .slick-arrow.slick-next {
    right: -5px;
    z-index: 5;
  }

  .slick-arrow.slick-next::before {
    color: black;
  }
}


