.articulos-conjunto-content{
  margin-top: var(--margin-top);
  min-height: fit-content;
  margin-bottom: 90px;

  .cards-holder{
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--cards-width), 1fr));

    grid-gap: 1em;

    .conjunto-card{
      background: orange;
      padding: 0px;
      min-height: fit-content;
      background: $kit_card_bg;
      transition: .3s ease-in-out;

      &:hover,
      &:focus-within{
        box-shadow: 0px 0px 12px rgba(0,0,0,.3);

        .image-holder{
          padding: 0px;
          }

        .content{
          margin-top: 15px;
        }
      }

      .image-holder{
        width: 100%;
        transition: .3s ease-in-out;
        display: flex;
        padding: 10px;

        a{
          display: block;
          width: 100%;
          height: fit-content;
          height: 100%;

        }
        img{
          aspect-ratio: 1/1;
          display: block;
          width: 100%;
          object-fit: cover;
          height: 100%;
        }
        
      }

      .content{
        transition: .3s ease-in-out;
        margin-top: 10px;
        text-align: center;
        padding: 0 5px 10px 5px;

        span{
          display: block;

          b{
            font-weight: 500;
          }
        }
      }
    }
  }

}
