.botonera-input {
  margin: unset;
  border: unset;
  max-width: 15px;
  display: grid;
  width: 100%;
  .btn-mas,
  .btn-menos {
    border: unset;
    width: 100%;
    padding: unset;
    height: 100%;
    background-color: $default_color;
  }
  .btn-mas {
    border-top-right-radius: 5px;
    border-bottom: 1px solid black;
  }
  .btn-menos {
    border-bottom-right-radius: 5px;
  }
}

// Resumen
.lineas-pedido {
  /* General */
  /*Chrome, Opera otros..*/
  input.input-cantidad-resumen-botones::-webkit-outer-spin-button,
  input.input-cantidad-resumen-botones::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0 !important;
  }

  /* Firefox */
  input.input-cantidad-resumen-botones[type='number'] {
    -moz-appearance: textfield;
  }

  .tt-input-counter.style-01.div-cantidad-resumen {
    min-width: unset;
    max-width: unset;
  }

  /* Button Pad Up/Down */
  input.input-cantidad-resumen-botones,
  input.input-cantidad-botones {
    padding: unset;
    margin: unset;
    border-top-right-radius: unset !important;
    border-bottom-right-radius: unset !important;
    box-shadow: unset !important;
  }
  input.input-cantidad-resumen-botones.modified {
    border-color: green !important;
  }

  /* Button Pad +/- */
  .tt-input-counter.style-01 {
    .input-qty-button-surrounded {
      min-width: 100%;
      width: 100%;
    }
  }
  .info-cantidad {
    .div-cantidad-resumen {
      .quantity-input.input-qty-resumen-button-surrounded {
        width: 50%;
        border-radius: unset !important;
        background-color: unset;
        text-align: center;
        border: unset;
      }
      .input-button-pad-remove,
      .input-button-pad-add {
        display: flex;
        justify-content: center;
        line-height: unset !important;
        width: 25%;

        .btn-plus,
        .btn-less {
          color: $default_color !important;
          background-color: unset !important;
          border: unset !important;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .btn-less:disabled,
        .btn-plus:disabled {
          color: grey !important;
        }
      }
    }
  }
}

// Ficha
.control_anadir_carrito_ficha {
  .input-group {
    /* General */
    input.input-cantidad-botones::-webkit-outer-spin-button,
    input.input-cantidad-botones::-webkit-inner-spin-button,
    input.input-qty-button-surrounded::-webkit-outer-spin-button,
    input.input-qty-button-surrounded::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0 !important;
    }
    input.input-cantidad-botones[type='number'],
    input.input-qty-button-surrounded[type='number'] {
      -moz-appearance: textfield;
    }
    /* Button Pad Up/Down */
    input.input-cantidad-botones {
      padding: unset;
      margin: unset;
      border-top-right-radius: unset !important;
      border-bottom-right-radius: unset !important;
      box-shadow: unset !important;
    }

    .botonera-input {
      min-height: 50px !important;
      height: 100% !important;
      margin-right: 3px;
      line-height: unset !important;
    }

    /* Button Pad +/- */
    .tt-input-counter.style-01 {
      .input-qty-button-surrounded {
        min-width: 100%;
        width: 100%;
      }
    }

    .input-qty-button-surrounded {
      min-width: 30%;
      width: 30%;
      border-radius: unset !important;
      background-color: unset;
      text-align: center;
      border: unset;
    }
    .input-button-pad-remove,
    .input-button-pad-add {
      display: flex;
      justify-content: center;
      line-height: unset !important;
      width: 8%;

      .btn-plus,
      .btn-less {
        color: $default_color !important;
        background-color: unset !important;
        border: unset !important;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btn-less:disabled,
      .btn-plus:disabled {
        color: grey !important;
      }
    }
  }
}

// Tabla
.control_anadir_carrito_tabla {
  .input-group {
    /* General */
    input.input-cantidad-botones::-webkit-outer-spin-button,
    input.input-cantidad-botones::-webkit-inner-spin-button,
    input.input-qty-button-surrounded::-webkit-outer-spin-button,
    input.input-qty-button-surrounded::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0 !important;
    }
    input.input-cantidad-botones[type='number'],
    input.input-qty-button-surrounded[type='number'] {
      -moz-appearance: textfield;
    }

    input.input-cantidad {
      max-width: 100% !important;
    }
    /* Button Pad Up/Down */
    input.input-cantidad-botones {
      max-width: 100% !important;
      padding: unset;
      margin: unset;
      border-top-right-radius: unset !important;
      border-bottom-right-radius: unset !important;
      box-shadow: unset !important;
    }
    .botonera-input {
      max-height: 40px !important;
      height: 100% !important;
      line-height: unset !important;
      .btn-mas,
      .btn-menos {
        max-height: 20px !important;
      }
    }
    /* Button Pad +/- */
    .tt-input-counter.style-01 {
      .input-qty-button-surrounded {
        min-width: 100%;
        width: 100%;
      }
    }

    .input-qty-button-surrounded {
      width: 30%;
      min-width: 30%;
      border-radius: unset !important;
      background-color: unset;
      text-align: center;
      border: unset;
    }
    .input-button-pad-remove,
    .input-button-pad-add {
      line-height: unset !important;

      .btn-plus,
      .btn-less {
        color: $default_color;
        background-color: unset;
        border: unset;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btn-less:disabled,
      .btn-plus:disabled {
        color: grey;
      }
    }
  }
}

// Lista
.control_anadir_carrito {
  .input-group {
    .detach-quantity-desctope.mobile {
      width: 90% !important;
      display: flex;
      justify-content: center;

      .input-cantidad-lista {
        width: 100% !important;
        max-width: unset !important;
      }
    }
    /* Button Pad Up/Down */
    .input-cantidad-lista {
      max-width: 90%;
      width: 50%;
    }
    .input-cantidad-botones-lista {
      width: 100%;
      min-width: 40%;
    }
    .botonera-input {
      line-height: unset !important;
      .btn-mas,
      .btn-menos {
        max-height: 20px !important;
      }
      .btn-mas {
        border-top-right-radius: unset !important;
      }
      .btn-menos {
        border-bottom-right-radius: unset !important;
      }
    }
    /* Button Pad +/- */
    .input-qty-button-surrounded {
      width: 100%;
      min-width: 26%;
      border-radius: unset !important;
      background-color: unset;
      text-align: center;
      border: unset;
    }
    .input-button-pad-remove,
    .input-button-pad-add {
      line-height: unset !important;

      .btn-plus,
      .btn-less {
        color: $default_color;
        background-color: unset;
        border: unset;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btn-less:disabled,
      .btn-plus:disabled {
        color: grey;
      }
    }
  }
}
