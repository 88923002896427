.article-list-badges {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px 5px 0px 5px;
}

.badge-comprado-reciente {
  margin-left: 2px;
  margin-right: 2px;
}

.badge-oferta {
  margin-left: 2px;
  margin-right: 2px;
}

.badge-novedad {
  margin-left: 2px;
  margin-right: 2px;
}

.badge-comprado-reciente > svg {
  background-color: #ecdc52;
  color: black;
  border-radius: 20px;
}

.badge-oferta > svg {
  background-color: #9fdb71;
  color: black;
  border-radius: 20px;
}

.badge-novedad > svg {
  background-color: #57c7ff;
  color: black;
  border-radius: 20px;
}
