#informacion-adicional {
  margin-bottom: 10px;
  >.container{
    max-width: unset;
    #infoAdicionalContent {
    #documentos {
      .listaDocumentos {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        margin: 0px 0px 20px 10px;
        text-decoration: none;
        list-style: none;
        padding-top: 1em;

        >button{
          display: flex;
          justify-content: center;
          align-content: center;
          cursor: pointer;
          margin: 3px;
          >svg{
            margin-right: 5px;
          }
        }
      }
    }
    
    #auxiliares{
      .listaAuxiliares{
        padding-top: 1em;
        .auxiliares{
          text-align: center;
        }

      }
    }
  }
  }
  
}
