.container-art-list-items {
    display: inline-flex;
    gap: 4rem;
    padding-top: 2rem;
}

.filtros {
    width: 30%;
}

.lista-articulos {
    width: 70%;
}