.container.plantillas-pedido{
  input[type=number]{

    background: #f7f8fa;
    display: block;
    width: 100%;
    height: 50px;
    font-size: 1.3em;
    line-height: 1em;
    text-align: center;
    border: none;
    outline: none;
    font-family: "Hind", sans-serif;
    font-weight: 500;
    color: $default_text_color;
    border-radius: 6px;

    &::placeholder{
      opacity: 0.4;
    }
  }

  .lista-plantilla-row{
    align-items: center;

    .row{
      margin: 0;
      padding: 0;

      &.actions{
        justify-content: space-between;
      }
    }

    button{
      border: none;
      background: $btn_colorbg;
      color: $btn_colortext;
      cursor: pointer;
      padding: 9px 5px;
      border-radius: 5px;
      text-align: center;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-items: center;
      justify-content: center;
      align-content: center;
    }

    div{
      text-align: center;
      padding: 4px;
    }
  }
  
  .cart-warn-plantillas {
    opacity: 0;
    display: block;
    margin-top: 9px;
    transition: 0.3s;
    text-align: center;
    color: rgb(245, 126, 47);
    font-weight: 500;
  }

  .btn-holder:hover ~ .cart-warn-plantillas {
    opacity: 1;
  }

  .btn-crear-pedido-plantilla{
    margin: auto;
    width: 100%;
    margin-top: 0px;
    background: $btn_colorbg;
    color: $btn_colortext;
    font-weight: bold;
  }

  .alert-holder {
    border: 1px solid rgba(122, 122, 122, 0.486);
    margin-bottom: 20px;
  }

  .messages-titles-plantilla{
    justify-content: center;
    align-items: center;
    font-size: 1.4em;
    font-weight: 500;
    padding: 5px;
    border-bottom: 1px solid rgba(122, 122, 122, 0.486);
    margin-bottom: 10px;

    .exit-holder {
      display: flex;
      text-align: right;
      justify-items: right;
      justify-content: right;
      cursor: pointer;
      transition: 0.3s;

      &:hover{
        color: rgb(255, 125, 125);
      }
    }
  }

  .plantilla-details{
    span.separator{

      display: block;
      width: 100%;
      height: 2px;
      border-radius: 4000px;
      background: rgb(235, 235, 235);
      margin: 9px;
    }

    .row{
      margin: 15px 0px;
      display: flex;
      text-align: center;
      justify-content: space-between;
      justify-items: space-between;


      .details-col {
        display: flex;
        text-align: center;
        justify-content: center;
        margin: 5px 0px;

        .title{
          font-weight: 900;
        }

        span {
          display: inline;
          font-size: 1.1em;
          padding: 3px 10px;
        }
      }
    }

    .plantilla-tag{
      background: #505050;
      color: #303030;
      font-weight: 500;
      padding: 3px 17px;
      color: white;
      margin: 0;
    }
  }

}
