.tt-mobile-parent-cart {
  .tt-dropdown-menu {
    max-height: 50px;
  }
}

.tt-dropdown-menu {
  max-height: 900px;
  overflow-y: auto;
}

.info-ped-pres {
  max-height: 600px;
  overflow-y: auto;
}

.info-ped-pres::-webkit-scrollbar {
  width: 5px;
}

.info-ped-pres::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.info-ped-pres::-webkit-scrollbar-thumb {
  background: #888;
}

.info-ped-pres::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.btn-nuevo-pedido {
  margin-top: 5px;
}

.tt-cart-content {
  padding: 0.75em !important;
  overflow-y: visible;
  position: relative;
  span.template {
    display: block;
    position: absolute;
    max-width: 30ch;
    text-align: right;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    right: 30px;
    top: 35px;
    color: $plantillas_carrito_fg;
  }
}

.tt-cart-list {
  margin-bottom: 1em;

  .tt-base {
    margin: unset !important;
    display: flex;
    .tt-title {
      width: 90%;
    }
    .tt-item-close {
      display: flex;
      justify-content: end;
      padding-right: 1em;
      width: 10%;
      z-index: 99;
    }
  }
  .tt-inf-qty-price {
    align-items: center;
  }
  .qty-price {
    display: flex;
    justify-content: right;
  }
  .btn-borrar-linea {
    border: unset;
    background: unset;
    cursor: pointer;
  }
}

.tt-cart-total-row {
  border: none !important;
  margin-right: 0.5em;
}

.info-pedido,
.info-presupuesto {
  border: 1px solid lightgray;
  padding: 0.5em;
  margin: 0.5em;
}

@media (min-width: 1025px) {
  .tt-cart .tt-dropdown-menu {
    width: 30% !important;
  }
}

@media (max-width: 1025px) {
  .tt-dropdown-menu {
    max-height: 100% !important;
  }
}
