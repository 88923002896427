.selector-cliente {
    text-transform: uppercase;
}

.tt-client-salect-content {
    padding: 0.75em !important;
    overflow-y: visible;
    position: relative;
  
    span.template{
      display: block;
      position: absolute;
      max-width: 30ch;
      text-align: right;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      right: 30px;
      top: 35px;
      color: $plantillas_carrito_fg;
    }
  
  }

@media (min-width: 1025px) {
    .tt-client-select .tt-dropdown-menu {
      width: 30% !important;
    }
  }
  
  @media (max-width: 1025px) {
    .tt-dropdown-menu {
      max-height: 100% !important;
    }
  }