.ReactModal__Overlay {
  z-index: 10;
}
.botonera-modal {
  display: flex;
  justify-content: center;
  > button {
    margin: 2px;
  }
}

.modal-carnet-profesional {
  .txt_numero_carnet,
  .txt_fecha_caducidad,
  .txt_nivel,
  .txt_descripcion,
  .txt_nombre,
  .txt_dni {
    margin-left: 2%;
    height: fit-content;
  }
}

.modal-receta {
  display: block;
  .checks {
    .col-4 {
      display: flex;
      justify-content: center;
    }
  }
  label {
    width: 100%;
    margin-bottom: 0.5em;
  }
  input {
    margin-bottom: 1em;
  }
}
