html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;

  .container {
    flex: 1;
  }
}

.menu-informes {
  .tt-desctop-menu .tt-megamenu-col-02 .dropdown-menu {
    z-index: 1;
  }
}

.contenedor_informes {
  .titulo {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .sel_informe_txt {
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 30px;
  }

  .tbScroll {
    overflow-x: auto;
    width: 100%;
    margin-bottom: 12px;
    > .row {
      width: 100%;
    }
  }
  .rowScroll {
    flex-wrap: nowrap;
    width: 95%;
  }

  .form-default {
    margin: 30px;
    padding: 20px;
    border: 1px solid #f7f8fa;
    border-radius: 10px;
  }

  .btn-plugin {
    padding: 5px 20px;
    margin: 5px 10px 5px 5px;
    height: 35px;

    svg {
      margin-right: 5px;
    }
  }

  .tt-shopcart-table {
    .titulos {
      font-weight: bold;
      margin: 0.25;
    }
  }

  .informes_mobile {
    display: block;
    .fila_titulo_y_botones {
      margin: unset;
      display: flex;
      padding: 2px;
      flex-wrap: nowrap;
      justify-content: center;
      .titulo {
        width: 100%;
      }
      .botones_imprimir {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin: 2px;
      }
    }
    .row {
      align-items: center;
      margin-top: 3px;
      margin-bottom: 3px;
    }
    .imprimir {
      margin-bottom: 20px;
      justify-content: flex-end;
    }
    > .col-sm-12.col-md-12.col-lg-12 {
      > .row:not(.imprimir) {
        border: 1px solid gray;
      }
    }

    .columna-plugins {
      width: 100%;
      margin-left: 3px;
      margin-right: 3px;
      > .btn {
        margin-top: 3px;
      }
    }
  }

  .informes_desctop {
    display: none;
    .row {
      align-items: center;
      margin-top: 1px;
      margin-bottom: 1px;
    }
    .imprimir {
      margin-bottom: 20px;
      justify-content: flex-end;
    }
  }

  .fila_titulo_y_botones {
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center !important;
    margin-bottom: 20px !important;
    .titulo {
      h2 {
        margin: 0 !important;
        padding: 0 !important;
        line-height: inherit;
      }
    }
    .botones_imprimir {
      display: grid;
      grid-template-columns: 2fr 2fr;
      grid-gap: 10px;
      .btn_descarga_informe {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 5px 10px;
        background-color: $default_color;
        color: white;
        > svg {
          margin-right: 5px;
        }
        > span {
          height: 20px;
        }
      }
      .btn_descarga_informe:hover {
        cursor: pointer;
      }
    }

    .boton_imprimir_masivo {
      margin-top: 10px;
      .btn_descarga_informe_masivo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 5px 10px;
        background-color: $default_color;
        color: white;

        > svg {
          margin-right: 5px;
        }
        > span {
          height: 20px;
        }
      }
      .btn_descarga_informe_masivo:hover {
        cursor: pointer;
      }
    }

    @media (max-width: 575px) {
      flex-direction: column;
    }
  }
  #filtros_informe_submit {
    svg {
      margin-right: 5px;
    }
  }
  .row.fila-t {
    font-weight: bold;
    font-size: 16px;
    background-color: gray;
    color: black;
  }
  .row.fila:nth-child(even) {
    background-color: lightgray;
  }
  .row.datos:hover {
    font-weight: bold;
    cursor: pointer;
  }
}

@media (min-width: 1026px) {
  .contenedor_informes {
    .informes_mobile {
      display: none;
    }
    .informes_desctop {
      display: block;
      .row {
        margin-left: unset !important;
        margin-right: unset !important;
        .col {
          flex-basis: unset;
          flex-grow: unset;
          max-width: unset;
        }
      }
      .fila-plugins {
        display: none;
        height: 30px;
        width: 100%;
        margin-left: 10px;
        margin-right: 10px;
        flex-wrap: nowrap;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .btn-plugin {
          margin: 2px;
          padding: 6px 8px;
          position: absolute;
          max-width: 32%;
        }
        > .ind0 {
          left: 15px;
        }
        > .ind2 {
          right: 15px;
        }
      }
    }
  }
}

@media (min-width: 1350px) {
  .contenedor_informes {
    max-width: 1300px;
  }
}

@media (min-width: 1450px) {
  .contenedor_informes {
    max-width: 1400px;
  }
}

@media (min-width: 1550px) {
  .contenedor_informes {
    max-width: 1500px;
  }
}
