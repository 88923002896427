.pending-lines{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;

  .buttons{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1em;
    box-sizing: border-box;

    @media(max-width: 700px){
      display: flex;
      flex-direction: column-reverse;
    }

  }

  .processing{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    font-size: 3em;
  }

  .line-data-grid{
    padding-bottom: 6px;
    margin-bottom: 20px;
    text-align: left;
    align-items: center;
    display: grid;
    grid-template-columns:  20px 2fr 1fr 1fr;
    column-gap: 1em;
    container-type: inline-size;

    b{
      font-weight: 800;
    }


    span.separator{
      display:block;
      height: 1px;
      background: rgb(242,242,242);
      grid-column-start: 1;
      grid-column-end: 5;
      &:nth-child(-n+1){
        display: none;
      }
    }
    div {
      padding: 10px;
      position: relative;

      &:nth-child(-n+5){
        @media(max-width: 700px){
          display: none;
        }
      }


      &:nth-child( 5n-4){
        @media(max-width: 700px){
          grid-column-start: 1;
          grid-column-end: 4;
          text-align: center;
          font-weight: 900;
          margin-top: 10px;
        }
        @container(max-width: 300px){
          grid-column-start: 1;
          grid-column-end: 4;
          text-align: center;
          font-weight: 900;
          margin-top: 10px;
        }
      }
    }

    span.price-tag{
      background: $lineas_pendientes_price_bg;
      color: $lineas_pendientes_price_fg;
      padding: 2px 9px;
    }
  }
}
