.cardOferta {
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
  width: 100%;
  max-width: 1400px;
  text-align: center;
}

.tt-oferta {
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.1);
  min-height: 450px !important;
  padding-left: 10px;
  padding-right: 10px;
}

.tt-oferta-code {
  font-size: 9px;
  text-align: right;
  padding-right: 6px;
}

.tt-oferta-principal-code {
  font-size: 9px;
  text-align: left;
  padding-left: 6px;
  margin-top: 0px;
}

.tt-close-oferta {
  font-size: 9px !important;
  text-align: right !important;
  padding-right: 20px !important;
}
.boton-custom {
  background: transparent;
  color: #929292;
  // border: 2px solid #a5a5a5;
  height: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.tt-oferta-fields,
.text {
  text-align: left;
  padding-left: 4px;
}

.tt-oferta-fields::-webkit-scrollbar {
  display: none;
}
.card-style::-webkit-scrollbar {
  display: none;
}
.card-style {
  scroll-behavior: smooth;
  overflow-y: scroll;
  padding-left: 0px;
  padding-right: 0px;
}
.card-seleccionada {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    opacity: 1;
    -webkit-appearance: auto;
  }
}

.imagen {
  padding-left: 20px;
  display: flex;
  justify-content: center;
  .tt-item-img {
    display: flex;
    max-width: 300px;
    max-height: 300px;
    img {
      object-fit: scale-down;
      display: block;
      image-rendering: optimizeSpeed;
    }
  }
}

.tt-oferta-button {
  button {
    text-align: right;
  }
}

.oferta-principal {
  width: 100%;
  height: auto;
  padding-left: 0px;
  text-align: center;
}

.card-seleccionada {
  max-width: 900px;
  padding-bottom: 20px;
  padding-top: 20px;
}
.tt-oferta-principal {
  text-align: left;
  padding-left: 0px;
}
.tt-div-fix {
  padding-left: 4px !important;
}

.stock-disponible {
  color: green;
}
.stock-no-disponible {
  color: red;
}
