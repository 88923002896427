.tabla-articulos {
  .tt-sort {
    display: inline-flex;
    align-items: center;
    a {
      display: inline-flex;
    }
  }
  .tabla_articulos_mobile {
    display: block !important;
    .row {
      align-items: center;
    }

    .col-sm-12.col-md-12.col-lg-12 {
      > .row {
        border: 1px solid gray;
        .titulos {
          font-weight: bold;
        }
        .etiqueta-precio {
          text-align: left;
        }
        .stock,
        .minimo_m,
        .multiplo_m,
        .descuento_m,
        .precio_m {
          text-align: center;
        }
      }
    }
  }

  .tabla_articulos_desctop {
    display: none !important;
    .row {
      align-items: center;
    }

    .boton-articulos-equ {
      border: unset;
      background: unset;
      display: flex;
    }
    .registro-articulos {
      .imagen {
        padding: unset;
        display: flex;
        justify-content: left;
        > a {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          > .tt-item-img {
            display: flex;
            width: 100px;
            height: 100px;
            img {
              object-fit: scale-down;
            }
          }
        }
      }
    }
    .stock_articulo {
      justify-content: center;
    }
  }

  .tabla_equivalentes_desctop {
    margin: unset;

    .tabla-articulos {
      width: 100%;
    }
  }
}

@media (min-width: 1500px) {
  .tabla-articulos {
    .control_anadir_carrito {
      justify-content: center;
      width: 70%;
      .form-control {
        width: 50%;
      }
      .input-group-append {
        width: 50%;
      }

      .mobile {
        display: none !important;
      }
      .desctop {
        display: flex !important;
      }
    }

    .tabla_articulos_mobile {
      display: none !important;
    }

    .tabla_articulos_desctop {
      display: block !important;
      .titulos {
        background-color: $default_color;
        color: white;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
      }
      .titulo_articulo {
        text-align: left !important;
      }
      .titulo_precio,
      .titulo_impuesto,
      .titulo_descuento,
      .titulo_minimo,
      .titulo_multiplo {
        text-align: center !important;
      }

      .articulo,
      .descripcion,
      .cometario,
      .acciones {
        margin-top: unset !important;
        text-align: left;
      }

      .precio {
        display: grid;
      }
    }
    .registro-articulos {
      padding-top: 1px;
      padding-bottom: 1px;
      text-align: center;

      .imagen {
        padding: unset;
        img {
          width: 100%;
        }
      }
    }
    .registro-articulos:nth-child(even) {
      border-bottom: 1px solid;
      border-top: 1px solid;
      border-style: groove;
      border-left: 0px;
      border-right: 0px;
    }
    .registro-articulos:last-child {
      border-bottom: 0px !important;
    }
    .form-observaciones-desplegables {
      margin: 0.5%;
    }
    /* .registro-articulos:nth-child(odd){
      background: red;

   }*/
  }
}
