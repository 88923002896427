.cargo-mensaje {
  color: red !important;
  font-size: 15px !important;
}

.detalles-tramos-plantillas {
  span.detalles-tramos-plantillas-header {
    display: block;
    width: 100%;
    color: $default_title_color;
    font-weight: 500;
    font-size: 1.2em;
  }

  ul {
    margin: 0;
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 5px;

      b {
        font-weight: 900;
      }
    }
  }
}
